export default defineI18nConfig(() => ({
  fallbackLocale: 'en',
  fallbackRoot: true,
  silentFallbackWarn: true,
  warnHtmlMessage: false,
  warnHtmlInMessage: false,
  globalInjection: true,
  datetimeFormats: {
    en: {
      short: {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      },
    },
    ar: {
      short: {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      },
    },
  },
  numberFormats: {
    en: {
      currency: {
        style: 'currency',
        currency: 'EGP',
        minimumFractionDigits: 0,
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 0,
      },
      percent: {
        style: 'percent',
        minimumFractionDigits: 0,
      },
      plain: {
        style: 'decimal',
        useGrouping: false,
        minimumFractionDigits: 0,
      },
    },
    ar: {
      currency: {
        style: 'currency',
        currency: 'EGP',
        minimumFractionDigits: 0,
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 0,
      },
      percent: {
        style: 'percent',
        minimumFractionDigits: 0,
      },
      plain: {
        style: 'decimal',
        useGrouping: false,
        minimumFractionDigits: 0,
      },
    },
  },
  pluralRules: {
    ar: choice => {
      if (choice < 0) {
        return 0;
      }

      // 0 => none
      // 1 => one
      // 2 => two
      if (choice >= 0 && choice <= 2) {
        return choice;
      }

      // Many
      if (choice >= 100) {
        return 4;
      }

      // Few
      const teen = choice % 100;
      if (teen >= 3 && teen <= 10) {
        return 3;
      }

      // Many
      if (teen >= 11 && teen <= 99) {
        return 4;
      }

      return 4;
    },
  },
}));
